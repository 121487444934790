/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a",
    code: "code",
    pre: "pre",
    h2: "h2",
    div: "div",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note"), ": if you looking for the events sent to the DOM on the client, refer to ", React.createElement(_components.a, {
    href: "/docs/api/v1/sdk/js/events"
  })), "\n", React.createElement(_components.p, null, "Backend Events are sent to the webhook URL using ", React.createElement(_components.code, null, "POST"), " with a ", React.createElement(_components.code, null, "JSON"), " event payload. The event format contains two fields ", React.createElement(_components.code, null, "type"), " and ", React.createElement(_components.code, null, "data"), ". The ", React.createElement(_components.code, null, "type"), " specifies the event type (see types below), and ", React.createElement(_components.code, null, "data"), " is a ", React.createElement(_components.code, null, "JSON"), " object representing the actual event data:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  \"type\": \"consent\",\n  \"text\": \"Some (optional) human-readable description of the event\",\n  \"data\": { ... }\n}\n")), "\n", React.createElement(_components.h2, {
    id: "example",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#example",
    "aria-label": "example permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Example"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "data"), " contains the full consent event (see ", React.createElement(_components.a, {
    href: "../consent"
  }, "Consent Schema"), " for a complete description)."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n    \"type\": \"consent\",\n    \"text\": \"Subject 1666a664380aad0d6d4ed0cb3357be28637b3a25a18b9b3f1c49501e7835d73a just consented to https://signatu.com/api/v1/policies/5\",\n    \"data\": {\n        \"subject\": \"1666a664380aad0d6d4ed0cb3357be28637b3a25a18b9b3f1c49501e7835d73a\",\n        \"issuer\": \"https://signatu.com/api/v1/datacontrollers/23\",\n        \"target\": \"https://signatu.com/api/v1/policies/5\",\n        \"source\": \"https://signatu.com/app/tab/integrate\",\n        \"action\": true,\n        \"token\": \"TMCMB6\",\n        \"createdAt\": \"2017-02-24T08:45:34.398Z\"\n    }\n}\n")), "\n", React.createElement(_components.h2, {
    id: "supported-events",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#supported-events",
    "aria-label": "supported events permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Supported Events"), "\n", React.createElement(_components.p, null, "Signatu supports the following events for Webhooks."), "\n", React.createElement(_components.p, null, "| Event             | Description                                                                                                    |\n| ----------------- | -------------------------------------------------------------------------------------------------------------- |\n| ", React.createElement(_components.code, null, "consent"), "         | Invoked whenever a new Consent event is registered. The ", React.createElement(_components.code, null, "data"), " payload will contain the actual consent event.  |\n| ", React.createElement(_components.code, null, "consent:refresh"), " | If ", React.createElement(_components.code, null, "refreshAt"), " is set for the ", React.createElement(_components.code, null, "ConsentEvent"), ", this event will be sent around the time ", React.createElement(_components.code, null, "refreshAt"), " expires.     |\n| ", React.createElement(_components.code, null, "trackerdetect"), "   | Invoked whenever a site scan discovers changes - i.e., when new trackers and/or cookies are found on the site. |\n| ", React.createElement(_components.code, null, "policy"), "          | Invoked whenever a Policy is published.                                                                        |"), "\n", React.createElement(_components.h3, {
    id: "policy-event",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#policy-event",
    "aria-label": "policy event permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Policy event"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n    \"userId\": 3,\n    \"type\": \"policy\",\n    \"text\": \"Policy ID 81829053c2be90fa was published to the Signatu CDN. PermaLink: https://cdn.signatu.com/policies/81829053c2be90, ActiveLink: https://cdn.signatu.com/policies/active/210b4445b70b11\",\n    \"data\": {\n        \"id\": \"81829053c2be90fa\",\n        \"activeLink\": \"https://cdn.signatu.com/policies/active/210b4445b70b11\",\n        \"permaLink\": \"https://cdn.signatu.com/policies/81829053c2be90\"\n    }\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
